import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { useVestaboardQueryParams } from "@vestaboard/installables/lib/hooks/useVestaboardParams";
import {
  Body,
  SubTitle,
} from "@vestaboard/installables/lib/components/Typography";
import {
  ActionButton,
  BoardPreview,
  Button,
  Divider,
  encodeBoardCharacters,
  Input,
  Modal,
  NotificationBanner,
  Spacer,
} from "@vestaboard/installables";
import { Box } from "@mui/material";
import { useIsQuietHours } from "../hooks/useQuietHours";

export const API_URL = "https://platform.vestaboard.com";

export const getSubscriptionCreds = async (subscriptionConfigToken: string) => {
  const response = await axios.get(
    `${API_URL}/v2.0/subscription-configuration`,
    {
      headers: {
        "X-Vestaboard-Subscription-Configuration-Token":
          subscriptionConfigToken,
      },
    }
  );

  const data = response.data as any;
  const subscriptionId = data["subscription"]["id"];
  const apiKey = data["subscription"]["installation"]["apiCredential"]["key"];
  const apiSecret =
    data["subscription"]["installation"]["apiCredential"]["secret"];

  return {
    subscriptionId,
    apiKey,
    apiSecret,
  };
};

export const getSubscriptionInfo = async (subscriptionConfigToken: string) => {
  const response = await axios.get(
    `${API_URL}/v2.0/subscription-configuration`,
    {
      headers: {
        "X-Vestaboard-Subscription-Configuration-Token":
          subscriptionConfigToken,
      },
    }
  );

  return response.data as any;
};

export const checkSetupComplete = async (personId: string, boardId: string) => {
  const response = await axios.post("/api/checksetup", {
    personId,
    boardId,
  });
  const data = response?.data as {
    setupComplete: boolean;
  };
  return data.setupComplete;
};

export const updateSubscriptionTitle = async (
  subscriptionId: string,
  subscriptionConfigToken: string,
  newTitle: string
) => {
  await axios.post("/api/subscription", {
    subscriptionId,
    subscriptionConfigToken,
    newTitle,
  });
};

export const Settings = () => {
  const { subscriptionId, subscriptionConfigToken } =
    useVestaboardQueryParams();
  const query = new URLSearchParams(window.location.search);
  const personId = query.get("person_id");
  const [error, setError] = useState("");
  const [openPreview, setOpenPreview] = useState(false);
  const [title, setTitle] = useState("");
  const [boardId, setBoardId] = useState("");
  const [setupComplete, setSetupComplete] = useState(false);

  const { isQuietHours, formattedQuietHours } = useIsQuietHours({ boardId });

  const saveSubscription = useCallback(
    async (
      subscriptionId: string,
      subscriptionConfigToken: string,
      personId: string
    ) => {
      const { subscription } = await getSubscriptionInfo(
        subscriptionConfigToken
      );
      const boardId = subscription.board[0].id;
      const boardTitle = subscription.board[0].title;
      if (
        boardTitle &&
        boardId &&
        personId &&
        subscriptionConfigToken &&
        subscriptionId
      ) {
        await axios.post("/api/board", {
          subscriptionId,
          subscriptionConfigToken,
          boardId,
          boardTitle,
          personId,
        });
        setError("");
        return true;
      } else {
        setError("There was an error authorizing your Vestaboard");
        return false;
      }
    },
    []
  );

  useEffect(() => {
    if (subscriptionConfigToken) {
      getSubscriptionInfo(subscriptionConfigToken).then(({ subscription }) => {
        if (subscription?.title) {
          // initialize title with user title if they customized their subscription
          setTitle(subscription?.title);
          const boardId = subscription.board[0].id;
          setBoardId(boardId);
        }
        const boardId = subscription.board[0].id;
        setBoardId(boardId);
      });
    }
  }, [subscriptionConfigToken]);

  useEffect(() => {
    personId &&
      boardId &&
      checkSetupComplete(personId, boardId).then((setupComplete) =>
        setSetupComplete(setupComplete)
      );
  }, [personId, boardId]);

  return (
    <>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "100vh",
          padding: 16,
          paddingTop: 32,
        }}
      >
        <Box
          style={{
            textAlign: "left",
            width: "100%",
            maxWidth: 600,
          }}
        >
          <NotificationBanner
            text={formattedQuietHours}
            visible={isQuietHours}
          />
          <SubTitle>Description</SubTitle>
          <Spacer size={"large"} />
          <Body>
            Receive automated messages from your favorite third-party services
            by linking Vestaboard to your Zapier account. This channel connects
            to a third party website.
          </Body>
          <Spacer size={"medium"} />
          <Box style={{ display: "flex", flexDirection: "row" }}>
            <ActionButton
              onClick={() => {
                setOpenPreview(true);
              }}
            >
              Preview
            </ActionButton>
            <div style={{ width: 16 }}></div>
            <ActionButton
              onClick={() => {
                window.open("https://www.vestaboard.com/help/zapier");
              }}
            >
              Help
            </ActionButton>
          </Box>
          <Spacer size={"large"} />
          <Divider />
          <Spacer size={"medium"} />
          <SubTitle>Channel Title</SubTitle>
          <Input
            label={""}
            placeholder={"Zapier"}
            value={title}
            onValueChange={(v) => {
              setTitle(v);
            }}
          />
          <Spacer size={"medium"} />
          {subscriptionId && subscriptionConfigToken && (
            <Button
              buttonType="white"
              onClick={async () => {
                await updateSubscriptionTitle(
                  subscriptionId,
                  subscriptionConfigToken,
                  title
                );
              }}
              disabled={false}
            >
              Update Channel Title
            </Button>
          )}
          <Spacer size={"large"} />
          <Divider />
          <Spacer size={"medium"} />
          <SubTitle>
            {setupComplete ? "Authorization Complete" : "Authorization Needed"}
          </SubTitle>
          <Spacer size={"medium"} />
          <Body>
            {setupComplete
              ? `You have authorized this Vestaboard to be used with Zapier. We recommend creating zaps on desktop for the best user experience.`
              : `To begin using zaps with your Vestaboard, we’ll need you to authorize your access to your Zapier account first. Click the button below to start that process.`}
          </Body>
          {!setupComplete && (
            <>
              <Spacer size="medium" />
              <Body>
                {
                  "Once authenticated, we recommend creating zaps on desktop for the best user experience."
                }
              </Body>
            </>
          )}
          <Spacer size={"medium"} />
        </Box>
        {error && (
          <>
            <Body>{error}</Body>
            <Spacer size={"medium"} />
          </>
        )}
        <Box
          style={{
            width: "100%",
            maxWidth: 600,
            display: "flex",
            flexDirection: "column",
            paddingTop: 24,
            marginLeft: 16,
            marginRight: 16,
          }}
        >
          {subscriptionId && subscriptionConfigToken && personId ? (
            <Button
              buttonType="white"
              onClick={async () => {
                const saved = await saveSubscription(
                  subscriptionId,
                  subscriptionConfigToken,
                  personId
                );
                if (saved) {
                  window.open("https://zapier.com/app/dashboard", "_blank");
                }
              }}
              disabled={false}
            >
              {setupComplete ? "Manage Zapier" : "Authorize Access to Zapier"}
            </Button>
          ) : (
            <Body>
              Error: This installable requires subscription_id,
              subscription_configuration_token, platform, and person_id as URL
              parameters
            </Body>
          )}
          <Spacer size={"medium"} />
        </Box>
      </Box>

      <Modal
        visible={openPreview}
        transparent
        fullScreenMobile
        onClose={() => {
          setOpenPreview(false);
        }}
      >
        <Box
          style={{
            padding: 16,
          }}
        >
          <BoardPreview
            isFavorite={false}
            characters={encodeBoardCharacters(
              `                         WAHOO! WE HAVE        A NEW ECOMMERCE       ORDER (#5435)         FOR $250.00`
            )}
          />
        </Box>
      </Modal>
    </>
  );
};
